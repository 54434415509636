import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import './userCountBar.css';

const UserCountBar = () => {
  const [userCount, setUserCount] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    // Connect to the server with the correct path
    const socket = io('https://connection.kick-yoon.com', {
      path: '/socket.io/',  // Ensure this matches the server's path
    });

    socket.on('connect', () => {
      console.log('Socket.io connection established');
    });

    socket.on('user_count', (data) => {
      try {
        if (data.count !== undefined) {
          setUserCount(data.count);
          setError(false);
        } else {
          throw new Error('Invalid data format');
        }
      } catch (error) {
        console.error('Error parsing socket.io message:', error);
        setError(true);
      }
    });

    socket.on('error', (error) => {
      console.error('Socket.io error:', error);
      setError(true);
    });

    socket.on('disconnect', () => {
      console.log('Socket.io connection closed');
    });

    // Cleanup on unmount
    return () => {
      socket.close();
    };
  }, []);

  const renderStatus = () => {
    if (error) {
      return '현재 킥윤 접속자: 정보 오류';
    } else if (userCount === null) {
      return '현재 킥윤 접속자: 정보수집중';
    } else {
      return `현재 킥윤 접속자: ${userCount}명`;
    }
  };

  // <a href='https://youtu.be/hUTsg30CKJE' target='_blank' rel="noreferrer"><nav class="ImportantInfoLive">LIVE: `尹대통령 탄핵청원` 청문회</nav></a>
  // <a href='https://youtu.be/ryFlqnKTZoI' target='_blank' rel="noreferrer"><nav class="ImportantInfoLive2">LIVE: 98차 촛불대행진</nav></a>

  return (
    <div>
    <nav className="userCountBar">
      {renderStatus()}
    </nav>
      <a href='https://www.ohmynews.com/NWS_Web/View/at_pg.aspx?CNTN_CD=A0003045718&CMPT_CD=P0010' target='_blank' rel="noreferrer"><nav class="ImportantInfo">국회 출석요구서 길바닥에 버린 대통령실</nav></a>
    </div>
  );
};

export default UserCountBar;
