import React from 'react';
import './menubar.css';

const MenuBar = () => {
  return (
    <nav className="menu-bar">
      <ul>
        <a href="/"><img srcSet='https://kick-yoon.com/CandleLight.png' alt='logo' className='logo' /></a>
        <li><a href="/">탄핵청원</a></li>
        <li><a href="/wtf">탄핵을왜</a></li>
        <li><a href="/changes">업데이트기록</a></li>
        <li><a href="/contact">Contact</a></li>
      </ul>
    </nav>
  );
};

export default MenuBar;
