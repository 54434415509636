import React, { lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MenuBar from './main/menubar';
import UserCountBar from './main/userCountBar';
import './App.css';

// Lazy load other pages
const About = lazy(() => import('./main/About'));
const Changes = lazy(() => import('./main/changes'));
const Contact = lazy(() => import('./main/Contact'));
const WhatIsWrongWithYoon = lazy(() => import('./main/WhatIsWrongWithYoon'));

// Main Page
const MainPage = lazy(() => {
  const now = new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' });
  const kstDate = new Date(now);
  const targetDate = new Date('2024-07-21T00:00:00+09:00');

  if (kstDate >= targetDate) {
    return import('./main/index_petition_finished');
  } else {
    return import('./main/index');
  }
});

function App() {
  useEffect(() => {
    const checkTimeAndRefresh = () => {
      const now = new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' });
      const kstDate = new Date(now);
      const targetDate = new Date('2024-07-21T00:00:00+09:00');
      
      if (kstDate >= targetDate) {
        window.location.reload();
      }
    };

    // Check every minute
    const intervalId = setInterval(checkTimeAndRefresh, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Load the gtag script asynchronously
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-361930884';
    script.async = true;
    document.head.appendChild(script);

    // Initialize gtag and set up event snippet
    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'AW-361930884');
        gtag('event', 'conversion', {
            'send_to': 'AW-361930884/VRGiCO-1uMEZEITByqwB',
            'value': 1.0,
            'currency': 'KRW'
        });
    };

    // Cleanup the script when the component unmounts
    return () => {
        document.head.removeChild(script);
    };
}, []);


  return (
    <React.StrictMode>
    <Router>
    <Helmet>
        <title>탄핵소추안 동의수 카운터</title>
        <meta name="description" content="윤석열 탄핵소추안 발의 청원 동의수 카운터" />
        <meta property="og:title" content="탄핵소추안 동의수 카운터" />
        <meta name="twitter:card" content="탄핵소추안 동의수 카운터" />
        <meta property="og:url" content="https://kick-yoon.com/" />
        <meta name="twitter:url" content="https://kick-yoon.com/" />
        <meta name="twitter:title" content="청원 동의수 실시간 현황 및 그래프" />
        <meta property="og:image" content="https://api.kick-yoon.com/graph.png" />
        <meta name="twitter:image" content="https://api.kick-yoon.com/graph.png" />
        <meta property="og:description" content="실시간 청원 동의수 현황 및 그래프를 확인할 수 있습니다." />
        <meta name="twitter:description" content="실시간 청원 동의수 현황 및 그래프를 확인할 수 있습니다." />
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet" />          
      </Helmet>
      <UserCountBar />      
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/wtf" element={<WhatIsWrongWithYoon />} />
        <Route path="/about" element={<About />} />
        <Route path="/changes" element={<Changes />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <MenuBar />
    </Router>
  </React.StrictMode>
  );
}

export default App;
